<template>
  <div>
    <supervisor-form-component :supervisor="supervisor" mode="edit" />
  </div>
</template>

<script>
import SupervisorFormComponent from "./supervisor-form-component.vue";
export default {
  components: {
    SupervisorFormComponent,
  },
  data() {
    return {
      supervisor: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        supervisor: {
          address: "",
          designation: "",
        },
      },
    };
  },
  created() {
    this.getSupervisor();
  },
  methods: {
    getSupervisor() {
      this.$store
        .dispatch("company/getCompanyUser", this.$route.params.supervisor_id)
        .then((response) => {
          this.supervisor = response.data;
        });
    },
  },
};
</script>

<style></style>
